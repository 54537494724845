@import url(https://fonts.googleapis.com/css?family=Space+Mono);
.dialog--header[data-v-4d259e79] {
  font-size: 16px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.dialog--subHeader[data-v-4d259e79] {
  font-size: 12px;
  font-weight: normal;
}
.dialog--text[data-v-4d259e79] {
  font-size: 12px;
  text-align: left;
}
.dialog--wrapper[data-v-4d259e79] {
  overflow-y: auto;
  overflow-x: hidden;
}
[data-v-4d259e79] b {
  color: var(--v-ternary-base);
}
/*# sourceMappingURL=src/components/common/dialogs/BaseDialog.css.map */
.copy-btn .copy-txt {
  position: absolute;
  left: 35px;
}
/*# sourceMappingURL=src/components/common/CopyButton.css.map */
.copyText {
  width: 100%;
  font-size: 11px;
  font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace;
  color: #fff;
}
/*# sourceMappingURL=src/components/common/dialogs/CopyTextDialog.css.map */
.number__wrapper[data-v-7f00faf2] {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  height: 200px;
  position: absolute;
}
.number[data-v-7f00faf2] {
  font-family: "Space Mono";
  font-size: 1em;
  transform: translateY(70px);
  -webkit-animation: rising-numbers-data-v-7f00faf2 0.7s linear infinite;
          animation: rising-numbers-data-v-7f00faf2 0.7s linear infinite;
}
.number[data-v-7f00faf2]::before {
  content: "1";
}
.number[data-v-7f00faf2]:nth-child(even)::before {
  content: "0";
}
.number[data-v-7f00faf2]:nth-child(2) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.number[data-v-7f00faf2]:nth-child(3) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.number[data-v-7f00faf2]:nth-child(4) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.number[data-v-7f00faf2]:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.number[data-v-7f00faf2]:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.number[data-v-7f00faf2]:nth-child(7) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.number[data-v-7f00faf2]:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.number[data-v-7f00faf2]:nth-child(9) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.number[data-v-7f00faf2]:nth-child(10) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
@-webkit-keyframes rising-numbers-data-v-7f00faf2 {
from {
    transform: translateY(70px);
    opacity: 0;
}
to {
    transform: translateY(-70px);
    opacity: 1;
}
}
@keyframes rising-numbers-data-v-7f00faf2 {
from {
    transform: translateY(70px);
    opacity: 0;
}
to {
    transform: translateY(-70px);
    opacity: 1;
}
}
/*# sourceMappingURL=src/pages/public/LoginPageLoader.css.map */
.top-padding {
  margin-top: -100px;
}
.logoTopSpace {
  margin-top: 100px;
}
.buttonSpace {
  margin-left: 10px;
}
/*# sourceMappingURL=src/pages/public/Login.css.map */
.text {
  font-size: 12px;
  border-radius: 10px;
}
.alternate-background {
  background: #eee;
}
.normal-background {
  background: #e0e0e0;
}
.progressBorder {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1rem;
}
/*# sourceMappingURL=src/components/UploadFile.css.map */
.checkbox-style[data-v-77552909] {
  border: 1px solid #808080;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 6px;
  background-color: var(--v-textFieldPrimary-base);
}
/*# sourceMappingURL=src/components/MetadataFields/CheckBoxComponent.css.map */
.metadataVForm[data-v-96fd606c] {
  overflow-y: scroll;
}
@media (min-width: 600px) {
.metadataVForm[data-v-96fd606c] {
    max-height: 38vh;
}
}
@media (min-width: 960px) {
.metadataVForm[data-v-96fd606c] {
    max-height: 43vh;
}
}
@media (min-width: 1264px) {
.metadataVForm[data-v-96fd606c] {
    max-height: 48vh;
}
}
@media (min-width: 1904px) {
.metadataVForm[data-v-96fd606c] {
    max-height: 53vh;
}
}
.item-center-horizontal[data-v-96fd606c] {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
  justify-items: center;
}
.item-center-vertical[data-v-96fd606c] {
  padding: 20vh 0;
  text-align: center;
}
/*# sourceMappingURL=src/components/MetadataComponent.css.map */
.v-input--switch {
  display: inline-block;
}
/*# sourceMappingURL=src/components/NotificationSubscription.css.map */
.button-group[data-v-3fd73d11] {
  margin: auto;
}
.button-class[data-v-3fd73d11] {
  float: right;
  margin-left: 0.5rem;
}
/*# sourceMappingURL=src/components/XmpUploadComponent.css.map */
.dropZone {
  height: 180px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
}
.dropZone .dropZoneCenter {
  outline: 2px solid #808080;
  outline-offset: -2px;
  border-radius: 5px;
  font-size: 0.8rem;
  height: 100%;
  padding-top: 25px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}
.dropZone .dropZone-over {
  outline-offset: -10px;
}
.dropZone .nonSelect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.center {
  text-align: center;
  color: #f00;
  font-size: 1rem;
}
.itemList .items {
  margin-top: 5px;
}
.splitpanes__pane {
  justify-content: center;
  align-items: center;
  display: flex;
}
.splitpanes--vertical > .splitpanes__splitter {
  min-width: 0.25rem;
  background: var(--v-scrollbarThumbColor-base);
  border-radius: 0.7rem;
}
.splitpanes--vertical > .splitpanes__splitter:hover {
  min-width: 0.25rem;
  background: var(--v-scrollbarThumbHoverColor-base);
  border-radius: 0.7rem;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}
/*# sourceMappingURL=src/pages/Upload.css.map */
.loader[data-v-9448d7b8] {
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;
}
.loader[data-v-9448d7b8]::before,
.loader[data-v-9448d7b8]::after {
  content: '';
  position: absolute;
}
.loader-item[data-v-9448d7b8] {
  display: flex;
}
.loader-butterfly[data-v-9448d7b8] {
  perspective: 500px;
  transform-style: preserve-3d;
  transform: rotate3d(2, 1, -1, 90deg);
}
.loader-butterfly[data-v-9448d7b8]::before,
.loader-butterfly[data-v-9448d7b8]::after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  background: #f00;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.loader-butterfly[data-v-9448d7b8]::before {
  left: 0;
  opacity: 0.7;
  border-top-right-radius: 0;
  transform-origin: right top;
  -webkit-animation-name: flapL-data-v-9448d7b8;
  animation-name: flapL-data-v-9448d7b8;
}
.loader-butterfly[data-v-9448d7b8]::after {
  left: 50%;
  opacity: 0.5;
  border-top-left-radius: 0;
  transform-origin: left top;
  -webkit-animation-name: flapR-data-v-9448d7b8;
  animation-name: flapR-data-v-9448d7b8;
}
@-webkit-keyframes flapL-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(-45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
}
0%, 100% {
    transform: rotateY(-30deg);
}
}
@keyframes flapL-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(-45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
}
0%, 100% {
    transform: rotateY(-30deg);
}
}
@-webkit-keyframes flapL-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(-45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
}
0%, 100% {
    transform: rotateY(-30deg);
}
}
@keyframes flapL-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(-45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
}
0%, 100% {
    transform: rotateY(-30deg);
}
}
@-webkit-keyframes flapR-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
}
0%, 100% {
    transform: rotateY(30deg);
}
}
@keyframes flapR-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
}
0%, 100% {
    transform: rotateY(30deg);
}
}
@-webkit-keyframes flapR-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
}
0%, 100% {
    transform: rotateY(30deg);
}
}
@keyframes flapR-data-v-9448d7b8 {
25%, 50% {
    transform: rotateY(45deg);
}
12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
}
0%, 100% {
    transform: rotateY(30deg);
}
}
/*# sourceMappingURL=src/components/common/Loading.css.map */
html {
  overflow-y: auto;
}
/* Common across all layouts */
.no-uppercase {
  text-transform: unset !important;
}
.v-navigation-drawer {
  overflow: visible !important;
}
.v-navigation-drawer__border {
  cursor: ew-resize;
  width: 5px;
  right: -5px;
}
.main_content {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
}
.current-design .theme--dark.v-navigation-drawer {
  background: var(--v-secondary-darken4) !important;
}
.mimir-redesign .theme--dark.v-navigation-drawer {
  background: var(--v-deepBlue-base) !important;
}
.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(255,255,255,0);
}
.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  background-color: rgba(255,255,255,0);
}
.features-rich #nav-drawer {
  overflow: hidden;
  top: 52px !important;
}
.features-rich #nav-drawer .v-list-item__action {
  margin: 6px 12px 6px 0;
}
.features-rich #nav-drawer .v-list-item__action i {
  font-size: 22px;
}
.features-rich #nav-drawer .v-list-item__title {
  padding-top: 1px;
}
.features-rich #nav-drawer .v-list-item--active:before {
  opacity: 0;
}
.features-export .v-list-item__action {
  margin-right: 8px !important;
}
*::-webkit-scrollbar {
  width: 16px;
  background-color: var(--v-scrollBarBgColor-base);
}
*::-webkit-scrollbar-track {
  border: 0.5px solid var(--v-scrollBarTrackBgColor-base);
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--v-scrollbarThumbColor-base);
}
*::-webkit-scrollbar-thumb:hover {
  background-color: var(--v-scrollbarThumbHoverColor-base);
}
.theme--dark.v-application {
  background-color: var(--v-vMenuBgColor-base);
}
.theme--dark.v-list {
  background: var(--v-vMenuBgColor-base);
}
.theme--dark.v-list-item:hover:before {
  opacity: 0.14;
}
.theme--light.v-application {
  background-color: var(--v-vMenuBgColor-base);
}
.theme--light.v-list {
  background: var(--v-vMenuBgColor-base);
}
.theme--light.v-list-item:hover:before {
  opacity: 0.14;
}
.custom-table-color th {
  background: var(--v-tableHeaderBgColor-base);
}
.custom-table-color tr {
  background: var(--v-tableRowBgColor-base);
}
.theme--dark.v-picker__body {
  background: var(--v-vMenuBgColor-base);
}
.theme--dark.v-card {
  background-color: var(--v-vMenuBgColor-base);
}
.v-menu__content {
  background-color: var(--v-vMenuBgColor-base);
}
.theme--dark.v-time-picker-clock {
  background: var(--v-bgColor-base);
}
.v-time-picker-title {
  color: var(--v-timePickerTitleColor-base);
}
.theme--dark.v-skeleton-loader .v-skeleton-loader__article {
  background: transparent !important;
}
.theme--light.v-skeleton-loader .v-skeleton-loader__article {
  background: transparent !important;
}
.v-btn-toggle > .v-btn.v-btn--active {
  border-bottom: 4px solid #3a85c9;
}
/*# sourceMappingURL=src/pages/layout/Layout.css.map */
.border-field[data-v-efe49dd6] {
  border: 2px solid var(--v-bgSecondary-base);
  margin-bottom: 5px;
  border-radius: 3px;
}
.border-checkbox[data-v-efe49dd6] {
  margin: 3px solid var(--v-bgSecondary-base);
  padding: 10px;
}
/*# sourceMappingURL=src/admin/components/MetaData.css.map */
.no-uppercase[data-v-0108cd8f] {
  text-transform: unset !important;
}
/*# sourceMappingURL=src/admin/components/activityFields/ClearFilter.css.map */
.no-uppercase[data-v-9b3337c6] {
  text-transform: unset !important;
}
/*# sourceMappingURL=src/admin/components/activityFields/FilterBtn.css.map */
.table-hover-cursor tbody tr[data-v-674178f9]:hover {
  cursor: pointer;
}
/*# sourceMappingURL=src/admin/components/LoadUser.css.map */
.no-uppercase[data-v-15ee0e80] {
  text-transform: unset !important;
}
/*# sourceMappingURL=src/admin/Dashboard.css.map */
.expansion-panel-custom {
  background: var(--v-textFieldPrimary-base) !important;
}
/*# sourceMappingURL=src/admin/components/SystemSetting.css.map */
.no-uppercase[data-v-3bd494ed] {
  text-transform: unset !important;
}
.no-hover tbody tr[data-v-3bd494ed]:hover {
  background-color: transparent !important;
}
/*# sourceMappingURL=src/components/MetadataHistory.css.map */

/*# sourceMappingURL=app.7f78fbb4.css.map*/